<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Add Voucher
        <div class="card-header-actions">
          <a
            class="card-header-action"
            href="physical"
            rel="noreferrer noopener"
          >
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
        <b-row>
          <b-col sm="12">
            <!--<h6>Simple Form</h6>-->
            <b-form
              v-on:submit.prevent="onSubmit"
              novalidate
              action="https://vuejs.org/"
            >
            <b-form-group
                id="user_type"
                label="User Type"
                label-for="user_type"
              >
                <b-form-select
                  id="user_type"
                  v-model="form.user_type"
                  disabled
                  aria-describedby="userTypeFeedback"
                  :plain="true"
                  :options="[
                    'All',
                    'New User',
                    'Register At',
                    'First Time Buyer',
                    'Last Time Buy',
                  ]"
                >
                </b-form-select>
              </b-form-group>
              <b-row v-if="form.user_type === 'Register At'">
                <b-col sm>
                  <b-form-group
                id="registerStart"
                label="Register Start"
                label-for="register_start"
              >
                <b-form-input
                  id="register_start"
                  type="text"
                  disabled
                  v-model="register_start"
                  autofocus
                />
              </b-form-group>
                </b-col>
                <b-col sm>
                  <b-form-group
                id="registerEnd"
                label="Register End"
                label-for="register_End"
              >
                <b-form-input
                  id="register_end"
                  type="text"
                  disabled
                  v-model="register_end"
                  autofocus
                />
              </b-form-group>
                </b-col>
              </b-row>
              <b-form-group
                id="days"
                v-if="form.user_type === 'Last Time Buy'"
                label="Days"
                label-for="day"
              >
                <b-form-input
                  id="day"
                  type="number"
                  v-model="form.day"
                  aria-describedby="dayFeedback"
                  placeholder="0"
                  autocomplete="given-name"
                  autofocus
                />
              </b-form-group>
              <b-form-group
                id="redeem_type"
                label="Redeem Type"
                label-for="redeem_type"
              >
                <b-form-select
                  id="redeem_type"
                  v-model="form.redeem_type"
                  aria-describedby="typeFeedback"
                  disabled
                  :plain="true"
                  :options="[
                    { value: 'single', text: 'Single Nominal Redeem' },
                    { value: 'multiple', text: 'Multiple Nominal Redeem' },
                  ]"
                >
                </b-form-select>
              </b-form-group>
              <b-row>
                <b-col sm="6">
                  <b-form-group
                id="nameInputGroup1"
                label="Voucher Name"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  type="text"
                  disabled
                  v-model="form.name"
                  aria-describedby="nameFeedback"
                  placeholder="Please Enter Voucher Name"
                  autocomplete="given-name"
                  autofocus
                />
              </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    id="prefixInputGroup1"
                    label="Voucher Prefix"
                    label-for="prefix"
                  >
                    <b-form-input
                      id="prefix"
                      type="text"
                      disabled
                      v-model="form.prefix"
                      aria-describedby="prefixFeedback"
                      placeholder="Please Enter Voucher Prefix"
                      onKeyPress="if(this.value.length==3) return false;"
                      autocomplete="given-name"
                      autofocus
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6">
                  <b-form-group
                    id="amountInputGroup1"
                    label="Voucher Amount"
                    label-for="amount"
                  >
                    <b-form-input
                      id="amount"
                      disabled
                      type="number"
                      v-model="form.amount"
                      aria-describedby="amountFeedback"
                      placeholder="0"
                      autocomplete="given-name"
                      autofocus
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    id="price"
                    label="Voucher Price"
                    label-for="price"
                  >
                    <b-form-input
                      id="price"
                      type="number"
                      disabled
                      v-model="form.price"
                      aria-describedby="priceFeedback"
                      placeholder="0"
                      autocomplete="given-name"
                      autofocus
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6">
                  <b-form-group
                    id="promotion_type"
                    label="Price Promotion Type (Optional)"
                    label-for="promotion_type"
                  >
                    <b-form-select
                      id="promotion_type"
                      v-model="form.promotion_type"
                      disabled
                      :plain="true"
                      :options="[
                        { value: 'fixed', text: 'Fixed Price' },
                        { value: 'percent', text: 'Discount' },
                      ]"
                    >
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    id="promotion_amount"
                    label="Nilai Promosi (Optional)"
                    label-for="promotion_amount"
                  >
                    <b-form-input
                      id="promotion_amount"
                      type="number"
                      disabled
                      v-model="form.promotion_amount"
                      aria-describedby="promotionAmountFeedback"
                      placeholder="0"
                      autocomplete="given-name"
                      autofocus
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6">
                  <b-form-group
                    id="qtyInputGroup1"
                    label="Voucher Qty"
                    label-for="qty"
                  >
                    <b-form-input
                      id="qty"
                      type="number"
                      v-model.lazy.trim="$v.form.qty.$model"
                      :state="chkState('qty')"
                      aria-describedby="quantityFeedback"
                      placeholder="0"
                      autocomplete="given-name"
                      autofocus
                    />
                    <b-form-invalid-feedback id="quantityFeedback">
                      <span v-if="!$v.form.qty.required"
                        >- Jumlah voucher tidak boleh kosong!</span
                      >
                      <span v-if="!$v.form.qty.numeric"
                        >- Jumlah voucher tidak boleh kurang dari 0!</span
                      >
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <div>
                    <b-form-group
                      class="mb-3"
                      label="Voucher Period Start From and End To :"
                      label-for="daterange"
                    >
                      <b-input-group>
                        <b-input-group-prepend is-text>
                          <i class="fa fa-calendar"></i>
                        </b-input-group-prepend>
                        <date-range-picker
                          id="daterange"
                          :start="startDate"
                          :end="endDate"
                          @picker="changePicker"
                        ></date-range-picker>
                      </b-input-group>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
              <b-button type="submit" variant="primary"> Submit </b-button>
            </b-form>
            <br />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  numeric,
} from "vuelidate/lib/validators";
import dotenv from "dotenv";
import moment from "moment";

dotenv.config();

export default {
  name: "generate-fisik",
  data() {
    return {
      register_start: moment().format("YYYY-MM-DD"),
      register_end: "",
      startDate: "",
      endDate: "",
      form: {
        user_type: "All",
        redeem_type: "single",
        name: "",
        amount: "",
        price: "",
        promotion_type: "",
        promotion_amount: "",
        qty: "",
        prefix: "",
        selectedImage: "",
        day: "",
      },
      isLoading: false,
    };
  },
  computed: {
    formStr() {
      return JSON.stringify(this.form, null, 4);
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
    isDirty() {
      return this.$v.form.$anyDirty;
    },
    invCheck() {
      return "You must accept before submitting";
    },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      qty: {
        required,
        numeric,
      },
    },
  },
  created() {
    this.$http.get(`physical/` + this.$route.params.id).then((res) => {
      let result = res.data.data
      this.form.name = result.name
      this.form.user_type = result.user_type
      this.form.redeem_type = result.redeem_type
      this.form.amount = result.amount
      this.form.price = result.price
      this.form.prefix = result.prefix
      this.form.promotion_type = result.promotion_type
      this.form.promotion_amount = result.promotion_amount
    })
  },
  methods: {
    onFileSelected(event) {
      this.form.selectedImage = event.target.files[0];
    },
    changePicker(value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isLoading = true;
        const formData = new FormData();

        formData.append("qty", this.form.qty);
        formData.append("start_from", this.startDate);
        formData.append("end_to", this.endDate);
        formData.append("setting", this.$route.params.id)

        this.$http
          .post(`physical`, formData)
          .then(() => {
            this.isLoading = false;
            this.$router.push({name: 'Detail Voucher Physical', params: { id: this.$route.params.id }})
            this.$toasted.success("Voucher Fisik successfully created!");
          })
          .catch((error) => {
            if (error.response) {
              this.isLoading = false;
              if (error.response.data.meta.code == 422) {
                this.$toasted.error(
                  "Please fill out the form that must be required"
                );
              } else if (error.response.data.meta.code == 400) {
                this.$swal.fire(
                  "Failed!",
                  error.response.data.meta.message,
                  "error"
                );
              }
            }
          });
      }
    },
    chkState(val) {
      const field = this.$v.form[val];
      return !field.$dirty || !field.$invalid;
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some((child) => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
  },
};
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}

#dateFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
